<template>
  <section class="category">
    <h1 class="category-title mb-7 text-capitalize">{{ title }}</h1>
    <v-slide-group center-active v-model="selected" :multiple="multiple" active-class="category-selected">
      <div v-for="(item, index) in items" :key="index">
        <ModifierItem :item="item"
          :index="index"
          :totalSelected="totalSelected"
          :quantifiable="quantifiable"
          :is-size="modifiergroup.type === 'sizes'"
          :modifiergroup="modifiergroup"
          :is-combo="isCombo"
          :bus="bus"
          @increase="increase"
          @decrease="decrease"
          @active-item="activeItem"
          @select-size="selectSize" />
      </div>
    </v-slide-group>
  </section>
</template>
<script>
import ModifierItem from "@/components/modifier/ModifierItem.vue";
import Vue from 'vue';
export default {
  name: "ModifierList",
  components: { ModifierItem },
  props: {
    title: {},
    items: [],
    modifiergroup: {},
    parentBus: {
      type: Vue
    },
    isCombo: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    multiple() {
      if(this.modifiergroup?.type?.toLowerCase() != 'sizes' && this.modifiergroup.hasOwnProperty('type') && this.modifiergroup?.maximum > 1 && this.modifiergroup.hasOwnProperty('maximum')) {
        return true
      } else {
        return false
      }
    },
    quantifiable() {
      if(this.modifiergroup?.type?.toLowerCase() != 'sizes' && this.modifiergroup.hasOwnProperty('type') && this.modifiergroup?.maximum > 1 && this.modifiergroup.hasOwnProperty('maximum')) {
        return true
      } else {
        return false
      }
    }
  },
  data: () => ({
    selected: [],
    totalSelected: 0,
    bus: new Vue()
  }),
  mounted() {
    this.parentBus.$on('emit-active-items', () => {
      this.emitActiveItems()
    })
    this.parentBus.$on('emit-clear-selected-items', () => {
      this.emitClearSelectedItems()
    })
  },
  methods: {
    activeItem(item) {
      this.$emit('active-item', item)
    },
    emitActiveItems() {
      this.bus.$emit('emit-active-items')
    },
    emitClearSelectedItems() {
      this.bus.$emit('emit-clear-selected-items')
    },
    selectSize(size) {
      this.$emit('select-size', size)
    },
    increase() {
      this.totalSelected++
    },
    decrease() {
      this.totalSelected--
    }
  }
};
</script>
<style lang="scss" scoped>
.category {
  &-selected {
    border: 5px solid var(--v-primary-base);
  }
  &-title {
    font-family: Work Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 42px;
    color: #000000;
    margin-top: 18.23px;
  }
}
</style>
