<template>
  <v-slide-item v-slot="{active, toggle }">
    <v-card ref="card" min-width="222.74" max-width="222.74" class="card shadow mb-8 px-5 mx-4 py-5" flat @click="select(toggle, active)" :ripple="false">
      <v-card-text class="card-content ">
        <div class="d-flex justify-center img-wrapper">
          <v-img :src="imgURL" contain></v-img>
        </div>
        <div class="d-flex justify-center mt-3 quantity-wrapper" v-if="quantifiable && active">
          <QuantityOption
            v-model="quantity"
            @increase="increase"
            @decrease="decrease"
            :minusColor="$vuetify.theme.themes.light.primary"
            :plusColor="$vuetify.theme.themes.light.primary"
            :size="48"
            :totalSelected="totalSelected"
            :maximum="maximum" />
        </div>
      </v-card-text>
      <v-card-actions class="card-action">
         <div class="text-center">
          <div class="card-title">{{itemName | name}}</div>
          <div class="card-price mt-3" v-if="!isSize">{{itemPrice | price}}</div>
      </div>
      </v-card-actions>
    </v-card>
  </v-slide-item>
</template>
<script>
import itemMixin from '@/mixins/item'
import translationMixin from '@/mixins/translations'
import QuantityOption from '@/components/base/QuantityOption.vue';
import Vue from 'vue';
export default {
  name: "ModifierItem",
  components:{
    QuantityOption
  },
  mixins: [itemMixin, translationMixin],
  props: {
    index: {
      type: Number
    },
    totalSelected: {
      type: Number,
    },
    item: {
      required: true,
    },
    quantifiable: {
      default: false,
      type: Boolean
    },
    isSize: {
      default: false,
      type: Boolean
    },
    isCombo: {
      type: Boolean,
      default: false
    },
    modifiergroup: {
      type: Object
    },
    bus: {
      type: Vue
    }
  },
  data:()=>({
    quantity: 1,
    activeItem: false,
  }),
  computed: {
    imgURL() {
      if(this.isCombo) {
        return this.item['image-uri']
      }
      return this.item.attributes['image-uri']
    },
    maximum() {
      if(this.modifiergroup?.maximum) {
        return this.modifiergroup.maximum
      }
      return 0
    },
    itemName() {
      if(this.isCombo) {
        return this.item.name
      }
      return this.item.attributes.name
    },
    itemPrice() {
      if(this.isCombo) {
        return this.item.price
      }
      return this.item.attributes.price
    }
  },
  mounted() {
    this.bus.$on('emit-active-items', () => {
      if (this.$refs.card?.$el?.classList?.contains('category-selected')) {
        this.$emit('active-item', {
          ...this.item,
          quantity: this.quantity,
          isSize: this.isSize,
        });
      }
    })
    this.bus.$on('emit-clear-selected-items', () => {
      if (this.$refs.card?.$el?.classList?.contains('category-selected')) {
        if (this.$refs?.card?.$el) {
          this.$refs.card.$el.click();
        }
      }
      setTimeout(() => {
        this.forceSelect();
      }, 200);
    });
    this.forceSelect();
  },
  methods: {
    forceSelect() {
      if((this.isSize || this.isCombo) && !this.index) {
        this.$refs.card.$el.click()
      } else if(this.modifiergroup?.type === 'options' && this.modifiergroup.minimum === this.modifiergroup.maximum && !this.index && this.modifiergroup.maximum) {
        this.$refs.card.$el.click()
      }
    },
    select(toggle, active) {
      if(this.isSize || this.isCombo) {
        if(!active) {
          toggle()
          this.activeItem = true
          if(this.isSize) {
            this.$emit('select-size', this.item)
          }
        }
      } else if (this.modifiergroup.hasOwnProperty('maximum') && this.totalSelected < this.modifiergroup?.maximum && !active) {
        toggle()
        this.increase()
      } else if (this.modifiergroup.hasOwnProperty('maximum') && this.totalSelected <= this.modifiergroup?.maximum && active) {
        toggle()
        for (let index = 0; index < this.quantity; index++) {
          this.decrease()
        }
        this.quantity = 1
      }
    },
    increase() {
      if(this.modifiergroup?.maximum > 1 && this.totalSelected < this.modifiergroup?.maximum) {
        this.$emit('increase')
      }
    },
    decrease() {
      this.$emit('decrease')
    }
  }
};
</script>
<style lang="scss" scoped>
.v-card--link:before {
  background: #fff !important;
}
.card {
  &-content{
    min-height: 180px;
  }
  &-action{
    display: unset !important;
  }
  background: #ffffff;
  border-radius: 20px;
  &-price{
    font-weight: 600;
    font-size: 22px;
    line-height: 100%;
    color: var(--v-primary-base);
  }
  &-title{
    color: #1E1E1E;
    font-family: Work Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 100%;
  }
  .img-wrapper {
    min-height: 170px;
  }
  .quantity-wrapper {
    height: 54px;
  }
  .v-card__actions.card-action {
    display: block !important;
    min-height: 100px;
  }
}
</style>
