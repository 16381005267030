<template>
  <v-card class="card px-5" flat style="min-height: 583px">
    <v-card-title class="justify-space-between pt-13">
      <v-img class="card-logo" :src="logoURI" max-width="155" alt="" />
      <h1 class="card-title">{{ translate('customize_food', 'Customize Food') }}</h1>
      <v-btn class="card-action" depressed @click.stop="$emit('on-reset')">{{ translate('clear','CLEAR') }}</v-btn>
    </v-card-title>
    <v-card-text class="item">
      <v-row align="center">
        <v-col cols="4" class="px-0">
          <v-img :src="item['image-uri']" eager />
        </v-col>
        <v-col cols="8">
          <h2 class="item-title mb-5">{{ item.name | name }}</h2>
          <div class="item-description" v-if="hasDescription">{{ item.description | name }}</div>
          <section class="mt-5 d-flex">
            <div class="item-price">
              {{ item.price | itemPrice }} <span class="item-calories">{{ item.calories | cal }}</span>
            </div>
            <div class="item-calories mx-2"><ButtonList :allergens="allergens(item.allergens)" /></div>
          </section>
          <section class="item-quantity mt-5">
            <QuantityOption
                v-model="quantity"
                :minusColor="$vuetify.theme.themes.light.primary"
                :plusColor="$vuetify.theme.themes.light.primary"
                :strict="false"
                width="220" />
          </section>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<style scoped lang="scss">
.card {
  position: fixed;
  width: 100%;
  z-index: 1;
  box-shadow: 0 24px 32px rgba(0, 0, 0, 0.04),
  0 16px 24px rgba(0, 0, 0, 0.04), 0 4px 8px rgba(0, 0, 0, 0.04),
  0 0 1px rgba(0, 0, 0, 0.04) !important;
  .item {
    &-quantity {
      width: 55%;
    }
    &-title {
      font-weight: 600;
      font-size: 42px;
      line-height: 120%;
      color: #000000;
    }
    &-description {
      font-weight: 600;
      font-size: 24px;
      line-height: 100%;
      color: rgba(0, 0, 0, 0.61);
    }
    &-price {
      font-weight: bold;
      font-size: 36px;
      line-height: 54px;
      color:var(--v-primary-base);
    }
    &-calories {
      font-weight: 400;
    }
  }
  &-action {
    background:var(--v-primary-base) !important;
    border-radius: 178px;
    width: 163px !important;
    height: 86.91px !important;
    font-weight: bold;
    font-size: 24px !important;
    line-height: 36px !important;
    text-align: center;
    color: #ffffff !important;
  }
  &-title {
    font-weight: 600;
    font-size: 48px;
    line-height: 56px;
    letter-spacing: -0.495px;
    color: #000;
  }
}
</style>

<script>
import { mapGetters } from 'vuex';
import itemMixin from '@/mixins/item'
import translationMixin from '@/mixins/translations'
import ButtonList from '@/components/base/ButtonList.vue';
import QuantityOption from '@/components/base/QuantityOption.vue';

export default {
  name: 'ItemDetails',
  components: {
    ButtonList,
    QuantityOption,
  },
  mixins: [itemMixin, translationMixin],
  props:{
    value: {
      type: Number,
      default: 1,
    },
    item: {

    },
  },
  mounted() {
    console.log('ItemDetails.vue: ', this.item);
  },
  computed:{
    ...mapGetters({
      menus: 'cart/getMenu',
      concept: 'concept/getConcept',
      locale: 'app/getLocale',
    }),
    logoURI() {
      return this.concept['logo-uri'];
    },
    quantity: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      },
    },
    hasDescription() {
      return this.item.description &&
          this.item.description[this.locale] &&
          this.item.description[this.locale] !== 'null';
    }
  },
};
</script>


